<template>
  <section class="diary">
    <div v-if="!show_diary" class="container vanishIn">
      <h3>{{ $t('course.diary.placeholder') }}</h3>
    </div>
    <div v-if="show_diary">
      <div class="has-text-centered dd">
        <b-dropdown
        animation="none"
        :scrollable="true"
        v-model="currentMenu"
        aria-role="list">
          <Button :text="currentMenu.title2" v-if="currentMenu.utype === 'train'"
          slot="trigger" :type="'hasIcon'" :icon="['fa', 'caret-down']" />
          <Button :text=" mindtrigger_or_anchor + ' ' + curr_anchor" slot="trigger"
          v-else :icon="['fa', ' caret-down']" :type="'hasIcon'" />
          <b-dropdown-item v-for="menu in menus"
          :key="menu.id" :value="menu" aria-role="listitem"
          @click="change_unit(menu)">
            <div class="media">
              <div class="media-content">
                <h4 v-if="menu.utype === 'train'">{{ menu.title2 }}</h4>
                <h4 v-if="menu.utype === 'anchor'">
                  {{ mindtrigger_or_anchor }} {{ menu.index - princi_length + 1 }}
                </h4>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="heading has-text-centered">
        <h1>{{currentMenu.title}}</h1>
      </div>
      <div v-for="(page, i) in unit_pages" :key="page.id">
        <div v-if="page.type === 'questions' || page.type === 'pquestion'"
        class="container notification">
          <div v-if="page.type === 'questions'">
            <h3>{{ page.question.q }}</h3>
            <div class="answers">
              <ul v-for="answer in page.question.options" :key="answer.id">
                <li>
                  <div class="field">
                    <b-checkbox v-if="page.subtype === 'mc_x' || page.subtype === 'sc_x'"
                    v-model="answer.u" disabled>
                      {{ answer.o }}
                    </b-checkbox>
                    <b-checkbox v-else v-model="answer.u" :class="color(answer)" disabled>
                      {{ answer.o }}
                    </b-checkbox>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="page.type === 'pquestion'">
            <h3>{{ page.text }}</h3>
            <div class="container">
              <p class="pquestion-answer"><pre class="panswer">{{ page.answer }}</pre>
              </p>
            </div>
          </div>
          <b-collapse v-if="!page.note_activated"
            class="card"
            :open="nun(page.note)"
            animation="slide"
            aria-id="contentIdForA11y3">
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open">
                <p class="card-header-title">
                  {{ $t('course.diary.personal-notes') }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <b-field>
                    <b-input v-model="page.note" maxlength="800" type="textarea"></b-input>
                </b-field>
              </div>
            </div>
          </b-collapse>
        </div>
        <div v-if="page.type === 'justtext'" class="container notification">
          <h3>{{page.title}}</h3>
          <div class="content">
            <span class="text" v-html="page.text"></span>
          </div>
          <b-collapse v-if="!page.note_activated"
            class="card"
            :open="nun(page.note)"
            animation="slide"
            aria-id="contentIdForA11y3">
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open">
                <p class="card-header-title">
                  {{ $t('course.diary.personal-notes') }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <b-field>
                    <b-input v-model="page.note" maxlength="800" type="textarea"></b-input>
                </b-field>
              </div>
            </div>
          </b-collapse>
        </div>
        <div v-if="page.type === 'video'" class="container mobile-nopad notification">
          <div v-if="page.watches > 4" class="is-8">
            {{ $t('course.diary.max-watches') }}
          </div>
          <b-field class="pb-2"
          v-if="user.client_id.audio_feature" :label="$t('course.course-main.audio')">
            <b-switch v-model="audio_only"></b-switch>
          </b-field>
          <div v-if="page.watches < 5">
            <Cvid v-if="audio_only" v-bind:audio="true" class="column is-8 is-offset-2"
              v-bind:page="page" v-bind:page_nr="i" v-bind:unit_nr="currentMenu.id" />
            <Cvid v-else v-bind:audio="false" class="column is-8 is-offset-2"
              v-bind:page="page" v-bind:page_nr="i" v-bind:unit_nr="currentMenu.id" />
            <div class="is-8">
              <h2>{{ page.watches }} {{ $t('course.diary.current-watches') }}</h2>
            </div>
          </div>
          <b-collapse v-if="!page.note_activated"
            class="card"
            :open="nun(page.note)"
            animation="slide"
            aria-id="contentIdForA11y3">
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open">
                <p class="card-header-title">
                  {{ $t('course.diary.personal-notes') }}
                </p>
                <a class="card-header-icon">
                  <b-icon
                    :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>
            <div class="card-content">
              <div class="content">
                <b-field>
                    <b-input v-model="page.note" maxlength="800" type="textarea"></b-input>
                </b-field>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Button from '../Common/Button.vue';
import Cvid from '../pagetypes/Cvid.vue';

export default {
  components: {
    Button,
    Cvid,
  },

  data() {
    return {
      currentMenu: {
        id: 0,
        title: 'Einheit 1',
        title2: this.$t('course.diary.training-placeholder'),
        utype: 'train',
      },
      audio_only: false,
    };
  },

  mounted() {
    if (this.$store.getters['Course/has_check']) {
      this.currentMenu.id = 1;
    } else {
      this.currentMenu.id = 0;
    }
    this.currentMenu.title2 = this.course.units[this.currentMenu.id].meta.title2;
    if (this.type === 'mindtrigger') this.currentMenu.title2 = this.$t('course.diary.mindtrigger-placeholder');
    this.currentMenu.title = this.menus[0].title;
  },

  computed: {
    isAdmin() {
      return this.$store.getters['User/is_admin'];
    },
    course() {
      return this.$store.getters['Course/get_course'];
    },
    type() {
      return this.$store.getters['Course/type'];
    },
    user() {
      return this.$store.getters['User/user'];
    },
    mindtrigger_or_anchor() {
      if (this.type === 'mindtrigger') return this.$t('common.mindtrigger');
      return this.$t('common-anchor');
    },
    curr_unit() {
      return this.$store.getters['Course/curr_unit'];
    },
    unit_pages() {
      return this.$store.getters['Course/unit_pages'](this.currentMenu.id);
    },
    princi_length() {
      return this.$store.getters['Course/princi'].length;
    },
    menus() {
      return this.$store.getters['Course/finished_units'];
    },
    curr_anchor() {
      return this.currentMenu.id - this.princi_length + 1;
    },
    start_id() {
      if (this.$store.getters['Course/has_check']) {
        return 1;
      }
      return 0;
    },
    show_diary() {
      let minunit = 1;
      if (this.$store.getters['Course/has_check']) {
        minunit = 2;
      }
      if (this.$store.getters['Course/curr_course'].max_unit >= minunit) {
        return true;
      }
      return false;
    },
  },

  methods: {
    nun(tn) {
      if (tn) {
        if (tn.length > 1) {
          return true;
        }
      }
      return false;
    },
    color(answer) {
      if (answer.a) {
        return 'glow-green';
      }
      return 'glow-red';
    },
    change_unit(menu) {
      this.currentMenu.id = menu.index;
    },
    alert_no_video() {
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$t('course.diary.alter-message-video'),
        position: 'is-bottom',
        type: 'is-danger',
      });
    },
  },
};
</script>

<style lang="scss">
.diary {

  h2 {
    font-size: 1.2rem !important;
  }

  padding-top: 0;
  .notification {
    max-width: 65rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .button-text {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.2rem;
    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  .card {
    margin: 1rem;
  }
  .is-clearfix::after {
    clear: none;
  }

  .dd {
    margin-bottom: 2rem;
    margin-top: 0rem;
  }

  .tooltip {
    float: right;
    padding-right: 12rem;
  }

  .heading {
    font-family: "Noto Sans";
    font-weight: 600;
    color: black;
    font-size: 2.5rem;
    padding-bottom: 1rem;
    @media only screen and (max-width: 600px) {
      font-size: 1.5rem;
    }
  }

  h3 {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
    font-size: 1.4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media only screen and (max-width: 600px) {
    font-size: 1rem;
    }
  }

  h4 {
    font-family: "Noto Sans";
    font-weight: 450;
    color: black;
  }

  .slide-enter-to {
    max-height: 100vh;
    transition: all 1s;
  }
  .slide-leave-to {
    transition: all 1s;
  }
  .slide-leave {
    max-height: 100vh;
  }

  .card-header-title {
    padding: 1rem;
  }
  .textarea:focus {
    border-color: transparent;
  }

  .pquestion-answer {
    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }

  .answers {
    font-family: "Roboto";
    font-weight: 450;
    color: black;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  .panswer {
    white-space: pre-wrap;
    word-break: break-word;
    font-family: "Roboto";
    color: black;
    font-size: 1.2rem;
    line-height: 2rem;
    padding-bottom: 1rem;
    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
    background: none;
  }

  .mobile-nopad {
    @media screen and (max-width: 1000px) {
      padding: 0;
    }
  }

  .small-video {
    max-width: 50rem;
    display: flex;
  }

  .glow-red {
    color: #C0564A;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #C0564A;
  }

  .glow-green {
    color: #00A237;
    border-radius: 10px;
    text-shadow: 1px 1px 3px #00A237;
  }
}

.vanishIn {
  animation-name: vanishIn;
  animation-duration: 2s;
}

@-webkit-keyframes vanishIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

@keyframes vanishIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(2, 2);
    filter: blur(90px);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
    filter: blur(0px);
  }
}

a.dropdown-item.is-active {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-text);
}

//animations   fade in
.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//fade out
.fade-out {
  -webkit-animation: fade-out 1s ease-out both;
  animation: fade-out 1s ease-out both;
}

@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
